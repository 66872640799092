import { createPinia, PiniaVuePlugin } from 'pinia'
import Vue from 'vue'

import App from '@/App.vue'
import api from '@/logic/api'
import { i18n } from '@/logic/i18n'
import { appSetup, init } from '@/logic/init/init'
import { initOnlineStatusSetup } from '@/logic/init/onlineStatusSetup'
import { relaunchApi } from '@/logic/relaunchApi'
import constantsMixin from '@/mixins/constants'
import AuthAPI from '@/modules/auth/api/auth'
import router from '@/router'
import store from '@/store'

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

function updateConfigFromId(storeInstance) {
  AuthAPI.getProfileConfig(`id:${storeInstance.getters['auth/config'].id}`).then((config) => {
    storeInstance.dispatch('auth/setConfig', config)
  })
}

function updateConfigFromSlug(storeInstance) {
  AuthAPI.getProfileConfig(`slug:${storeInstance.getters['auth/config'].slug}`).then((config) => {
    storeInstance.dispatch('auth/setConfig', config)
  })
}

function updateConfig(storeInstance) {
  if (storeInstance.getters['auth/isLoggedIn']) {
    updateConfigFromId(storeInstance)
  } else {
    updateConfigFromSlug(storeInstance)
  }
}

async function startApp(config = null) {
  if (config !== null && config.status !== 404) {
    store.dispatch('auth/setConfig', config)
  }
  api.setup(store, i18n, router)
  relaunchApi.setup(store, i18n, router)
  await init(store, i18n, router)
  if ((config && config.status !== 404) || store.getters['auth/hasConfig']) {
    await appSetup(store, i18n, router)
  }

  Vue.mixin(constantsMixin)

  new Vue({
    i18n,
    render: (h) => h(App),
    router,
    store,
    pinia,
  }).$mount('#app')

  if (config?.status === 404) {
    if (!store.getters['auth/hasConfig']) {
      // We don't have a config yet, so the user has to select their app first
      router.replace({
        name: 'auth.select-app',
      })
    } else {
      updateConfig(store)
    }
  }

  initOnlineStatusSetup()
}

Vue.config.productionTip = false
if (window.klConfig !== null) {
  // Config is already loaded, start the app
  startApp(window.klConfig)
} else if (store.getters['auth/hasConfig']) {
  // We already have the config in the store, start right away and update the config as soon as we get it
  startApp()
  window.addEventListener('configloaded', () => {
    if (window.klConfig && window.klConfig.status !== 404) {
      store.dispatch('auth/setConfig', window.klConfig)
    } else {
      updateConfig(store)
    }
  })
} else {
  // We have no config stored yet, so wait until its loaded
  window.addEventListener('configloaded', () => {
    startApp(window.klConfig)
  })
}
